import * as yup from 'yup';
import {
  BasicBlockProps,
  BasicBlockValidationSchema,
  ExtendBasicBlockSchema
} from 'components/editor/schema';
import { BlockWrapper } from './BlockWrapper';
import { ComponentTypes } from 'common/ComponentTypes';
import { CtaFormSchema, CtaProps, CtaSchema } from './Cta';
import { ExpandingArrowButton } from 'components/button/ExpandingArrowButton';
import { FormSchemaType } from 'common/types/FormSchemaType';
import { classNames } from 'utils/string';
import { sliceIntoChunks } from 'utils/array';
import { useHtmlParser } from 'hooks/useHtmlParser';
import { useRouter } from 'next/router';
import Parser from 'html-react-parser';

const widthOptions: string[] = [
  'border-0',
  'border',
  'border-2',
  'border-4',
  'border-6',
  'border-8'
];

export const BlockFormSchema: FormSchemaType = ExtendBasicBlockSchema({
  title: {
    type: ComponentTypes.TextField,
    label: 'Title',
    required: false
  },
  titleColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Title Color',
    required: false
  },
  body: {
    type: ComponentTypes.RichTextEditor,
    label: 'Body',
    required: false
  },
  textColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Text Color',
    required: false
  },
  textAlign: {
    type: ComponentTypes.Select,
    label: 'Text Alignment',
    required: false,
    additionalProps: {
      options: [
        { id: 'text-left', name: 'Left' },
        { id: 'text-center', name: 'Center' },
        { id: 'text-right', name: 'Right' }
      ]
    }
  },
  backgroundColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Background Color',
    required: false
  },
  borderColor: {
    type: ComponentTypes.ColorPicker,
    label: 'Border Color',
    required: false
  },
  borderWidth: {
    type: ComponentTypes.Select,
    label: 'Border Width',
    required: false,
    additionalProps: {
      options: widthOptions.map((border, ind) => {
        return {
          id: border,
          name: ind * 2
        };
      })
    }
  },
  ctas: {
    type: ComponentTypes.Array,
    label: 'CTAs',
    required: false,
    additionalProps: {
      itemSchema: CtaFormSchema.children
    }
  }
});

export const BlockSchema = BasicBlockValidationSchema.shape({
  title: yup.string().notRequired(),
  titleColor: yup.string().notRequired(),
  body: yup.string().notRequired(),
  textColor: yup.string().notRequired(),
  backgroundColor: yup.string().notRequired(),
  textAlign: yup
    .string()
    .notRequired()
    .oneOf(['text-left', 'text-center', 'text-right']),
  ctas: yup.array().of(CtaSchema)
});
export interface TypographyProps extends BasicBlockProps {
  textAlign: string;
  title?: string;
  titleColor?: string;
  body?: string;
  ctas?: CtaProps[];
  borderWidth?: string;
  borderColor?: string;
}
//md:grid-cols-1
//md:grid-cols-2
//md:grid-cols-3
//lg:grid-cols-1
//lg:grid-cols-2
//lg:grid-cols-3

const Typography = (props: TypographyProps) => {
  const {
    title,
    titleColor,
    body,
    textColor,
    textAlign,
    ctas,
    borderWidth,
    borderColor
  } = props;

  const router = useRouter();
  const parsedBody = useHtmlParser(body || '');

  const renderCtas = () => {
    const ctaColumns = ctas && ctas?.length ? sliceIntoChunks(ctas, 3) : [];
    const renderCtaRow = (index: number, items: CtaProps[]) => {
      return items.map((cta, i) => (
        <ExpandingArrowButton
          key={index + i}
          onClick={() => router.push(cta.url)}
          styletype={cta.type}
          overrides={cta.overrides}
          className="mx-auto sm:mb-4"
          label={cta.label}
          size={cta.size || 'medium'}
          arrowtype={'single'}
        />
      ));
    };
    return ctaColumns.map((items, i) => {
      const columnsClass =
        items.length === 1
          ? ''
          : items.length === 2
          ? 'md:grid-cols-2 lg:grid-cols-2'
          : 'md:grid-cols-3 lg:grid-cols-3';
      return (
        <div
          key={i}
          className={`grid grid-cols-1 gap-x-4 lg:my-4 w-full sm:grid-cols-1 ${columnsClass}
        `}
        >
          {renderCtaRow(i, items)}
        </div>
      );
    });
  };
  return (
    <BlockWrapper {...props}>
      <div
        className={classNames(
          textAlign,
          'container mx-auto max-w-5xl',
          borderWidth
        )}
        style={{ borderColor: borderColor }}
      >
        {title && (
          <h2 className="text-4xl pb-8 px-2" style={{ color: titleColor }}>
            {Parser(title)}
          </h2>
        )}
        {body && (
          <div
            className="body-text mb-6 text-[1.4rem] w-full"
            style={{
              color: textColor,
              padding: borderWidth && borderWidth !== '' ? '16px' : undefined
            }}
          >
            {parsedBody}
          </div>
        )}
        {renderCtas()}
      </div>
    </BlockWrapper>
  );
};

export default Typography;
